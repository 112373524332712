.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 2rem;
}

.steps::after {
  content: '';
  width: 250px;
  border-bottom: 1px solid #ccc;
  position: absolute;
  top: 20px;
}

.step {
  text-align: center;
  background-color: #fff;
  z-index: 1;
  width: 90px;
  padding: 0.5rem;
  display: none;
  /* Resto do seu código... */
}

.step p {
  text-align: center;
  white-space: nowrap;
  margin: 0; /* Remova a margem inferior padrão para que o texto fique mais próximo do ícone */
}

.step.active {
  display: block; /* Mostra o ícone ativo */
}

.step svg {
  font-size: 1.7rem;
  margin-bottom: 0.2rem;
  display: block;
  margin: 0 auto;
  fill: #383838;
  margin-bottom: 15px; /* Centraliza o ícone horizontalmente em relação ao texto */
}

.active > svg {
  fill: #00a2e9;
}

.super-step {
  width: 100px;
}

@media (min-width: 46.25em) {
  .steps {
    gap: 14rem;
  }

  .steps::after {
    width: 580px;
  }

  .super-step {
    width: 200px;
  }

  .super-step svg {
    font-size: 2.2em;
  }

  .step {
    display: block; /* Mostra todos os ícones na versão desktop */
  }
}
