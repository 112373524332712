body {
  font-family: 'Inter', sans-serif;
  text-align: center;
  margin: 0;
  background-image: url('./assets/cool-background.png');
  background-size: cover;
  background-position: center;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #ffffff 30%, #383838);
  opacity: 0.5;
  z-index: -1;
}

.alert-margin {
  margin-bottom: 40px;
}

.header a {
  text-decoration: none;
}

.actions button svg polyline {
  stroke: white !important;
}

header p {
  color: #fff;
  font-size: 1.3em;
  font-weight: bold;
}

.content-wrapper-center {
  padding: 3rem 1rem 2rem;
  max-width: 67.25rem;
  margin: auto;
}

.button-start-again {
  width: 12%; /* Reduzir a largura dos botões para melhor ajuste */
  margin: 0.5rem auto; /* Adicionar margens para espaçamento vertical */
  justify-content: center;
  align-items: center;
  flex-wrap: row;
  white-space: nowrap;
}

.hero-subtitle {
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  align-items: center;
  justify-content: center;
  word-wrap: break-word; /* Adicione esta linha */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adicione esta linha */
}

.hero_title {
  font-size: 28px;
  margin-top: 90px;
  color: #ffffff;
  text-align: center;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Adicione esta linha */
}

h1 {
  font-size: 2.5rem;
}

.header {
  position: relative; /* Adicione posicionamento relativo ao cabeçalho */
  height: 20vh;
  margin-bottom: 15px;
}

.logo {
  position: absolute; /* Posicionamento absoluto para centralizar */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Move o elemento de volta pela metade do seu tamanho */
}

.logo-image {
  width: 300px;
  height: auto;
  border-radius: 10px; /* Raio da borda */
}

.logo-footer {
  width: 250px;
  height: auto;
}

.actions {
  justify-content: space-between;
  flex-wrap: row;
}

.actions button {
  width: 20%; /* Reduzir a largura dos botões para melhor ajuste */
  margin: 0.5rem auto; /* Adicionar margens para espaçamento vertical */
}

.actions button .white-icon {
  color: white !important;
  fill: currentColor;
}

h2 {
  font-size: 2rem;
}

.header h1 {
  font-size: 1.5em;
}

.hide {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.form-main {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 3.5rem;
  margin-top: 3rem;
}

.footer {
  background-color: #fff;
  color: #383838;
}

.icons-social-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.icons-social-list li {
  display: inline-block;
}

.icons-social-list li a.icons-social {
  margin-right: 10px; /* Adicione margem à direita para criar espaço entre os ícones */
  display: inline-block; /* Isso garante que o ícone seja exibido corretamente */
}

.footer a {
  text-decoration: none;
}

.title-logo-final {
  text-transform: uppercase;
  text-decoration: none;
  color: #383838;
  font-weight: bold;
  font-size: 1.2rem;
}

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.5rem;
  grid-auto-flow: dense;
}

.row > .sm-12 {
  grid-column: 1 / -1;
}

.typpography-class {
  font-weight: bold;
  margin-left: 10px;
  text-decoration: none;
  border: 1px solid black;
  background-color: #383838;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  transition: 0.4s;
}

.typpography-class:hover {
  font-size: 1.2em;
}

.icons-social {
  font-size: 2em;

  color: #383838;
}

@media screen and (min-width: 46.25em) {
  body {
    text-align: left;
  }

  h1 {
    font-size: 3.625rem;
  }

  h2 {
    font-size: 2.875rem;
  }

  .header {
    align-items: center;
  }

  .title-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero_title {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .menu-btn,
  .menu-icon {
    display: none;
  }

  .header-menu {
    /* display: block; */
    margin: 0;
  }

  .header-menu li {
    display: inline-block;
    margin-left: 1.75rem;
  }

  .header-menu a {
    padding: 1rem;
  }

  .contato_image {
    display: block;
    text-align: center;
  }

  .row {
    gap: 1rem;
  }

  .row > .md-3 {
    grid-column: span 3;
  }

  .row > .md-4 {
    grid-column: span 4;
  }

  .row > .md-6 {
    grid-column: span 6;
  }

  .row > .md-9 {
    grid-column: span 9;
  }

  .row > .md-1-6 {
    grid-column: 1 / 7;
  }

  .row > .md-7-12 {
    grid-column: 7 / 13;
  }
}

@media screen and (min-width: 90em) {
  .hero_title {
    margin-bottom: 6.25rem;
  }

  .row {
    gap: 3rem;
  }

  .row > .lg-3 {
    grid-column: span 3;
  }

  .row > .lg-4 {
    grid-column: span 4;
  }

  .row > .lg-5 {
    grid-column: span 5;
  }

  .row > .lg-6 {
    grid-column: span 6;
  }

  .row > .lg-7 {
    grid-column: span 7;
  }

  .row > .lg-1-5 {
    grid-column: 1 / 6;
  }

  .row > .lg-1-7 {
    grid-column: 1 / 8;
  }

  .row > .lg-8-12 {
    grid-column: 8 / 13;
  }
}

/* hamburguer menu*/
.header {
  display: flex;
  justify-content: space-between;
}

.header-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  max-height: 0;
  overflow: hidden;
  background-color: white;

  transition: max-height 0.3s;
  margin: 0;
  padding-right: 1rem;
  padding-left: 1rem;
}

.menu-btn:checked ~ .header-menu {
  max-height: 100vh;
}

.menu-btn:checked + .menu-icon .nav-icon {
  background-color: transparent;
}

.header-menu:focus-within {
  max-height: 100vh;
}

@media screen and (min-width: 46.25em) {
  .hero.row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero_title {
    font-size: 35px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .hero-subtitle {
    margin-top: 5%;
    font-size: 28px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 46.24em) {
  .hero_title {
    margin-top: 200px;
  }
}

.header nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.menu-icon {
  position: relative;
  cursor: pointer;
  height: 24px;
  /* user-select: none; */
}

.nav-icon {
  background-color: #333;
  display: inline-block;
  height: 2px;
  width: 24px;
  position: relative;
}

.nav-icon::after,
.nav-icon::before {
  content: '';
  background-color: #333;
  display: inline-block;
  height: 2px;
  width: 24px;
  position: absolute;
  transition: transform 0.2s;
}

.nav-icon::after {
  transform: translateY(8px);
}

.nav-icon::before {
  transform: translateY(-8px);
}

.menu-btn:checked + .menu-icon .nav-icon::before {
  transform: rotate(-45deg);
}

.menu-btn:checked + .menu-icon .nav-icon::after {
  transform: rotate(45deg);
}

.menu-btn:focus-visible + .menu-icon {
  outline: 2px solid black;
  outline-offset: 3px;
}

.actions {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.actions-button-last {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.actions-button-last button {
  font-size: 1.2rem;
  padding: 0.7rem 1rem;
  display: flex;
  max-width: 250px;
  color: #fff;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-radius: 3px;
  background-color: #00a2e9;
  cursor: pointer;
  border: none;
  transition: 0.4s;
  flex-wrap: row;
  white-space: nowrap;
}

.actions-button-last button:hover {
  background-color: #6fd4ff;
  font-size: 1.3rem;
  color: black;
}

.actions button {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  max-width: 250px;
  color: #fff;
  gap: 0.5rem;
  align-items: center;
  border-radius: 3px;
  background-color: #00a2e9;
  cursor: pointer;
  border: none;
  transition: 0.4s;
  flex-wrap: row;
  font-weight: 600;
  white-space: nowrap;
}

.actions button:hover {
  background-color: #6fd4ff;
  font-size: 1.3rem;
  color: #fafafa;
}

.btn {
  border-radius: 5px;
  border: none;
  padding: 10px;
  background-color: #5cd325;
  transition: 0.5s;
}

.btn:hover {
  background-color: #51ff00;
  padding: 15px;
}

/* Call to Action Section */

#cta h3 {
  margin-bottom: 1.2em;
  font-size: 2em;
  font-weight: 500;
}

#cta .btn {
  width: 30%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 1.2em;
}

/* Footer */
#footer {
  text-align: center;
  color: #00a2e9;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#footer h3 {
  font-size: 1.8em;
  margin-bottom: 0.5em;
}

#footer p:nth-child(2) {
  margin-bottom: 1em;
  font-size: 1.2em;
}

#footer span {
  font-weight: bold;
}

/* Mobile */
@media (max-width: 450px) {
  /* Event form */
  #event-subscription {
    flex-direction: column;
    height: auto;
  }

  .logo {
    position: relative;
    text-align: center;
    margin: 0;
  }

  .hero_title {
    margin-top: 200px;
  }

  .logo-image {
    display: block; /* Mantém a imagem como um bloco */
  }
  #disclaimer {
    padding: 2em;
  }

  #disclaimer h2 {
    margin-bottom: 0.5em;
  }

  #subscription-form {
    max-width: 70%;
    margin-bottom: 2em;
  }

  /* Benefits section */
  .benefits {
    flex-direction: column;
    align-items: center;
    width: auto;
    padding: 2em;
  }

  .benefit {
    max-width: 100%;
    margin-bottom: 2em;
  }

  /* Event location */
  #location {
    flex-direction: column;
    height: auto;
    text-align: center;
    padding: 2em 0;
  }

  #address {
    order: 2;
    flex-direction: column;
    max-width: 100%;
  }

  #address i {
    margin: 0.3em 0;
  }

  #about-location {
    order: 1;
    max-width: 100%;
  }

  #about-location h3 {
    margin-bottom: 0;
  }

  #about-location p {
    display: none;
  }

  .actions {
    justify-content: center;
    flex-wrap: row;
  }

  .actions button {
    width: 50%; /* Reduzir a largura dos botões para melhor ajuste */
    margin: 0.5rem auto; /* Adicionar margens para espaçamento vertical */
  }

  /* Event details container */
  .detail {
    flex-direction: column;
    margin-bottom: 0.5em;
    gap: 0;
  }

  .detail img {
    width: 100%;
    margin: 2em 0;
  }

  #detail-1 img {
    display: none;
  }

  .detail-description {
    padding: 0;
  }

  #detail-3 img {
    order: 2;
  }

  #detail-3 .detail-description {
    order: 1;
  }

  /* Call to Action Section */
  #cta .btn {
    width: 80%;
  }
}
