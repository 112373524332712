.thanks-container {
  text-align: center;
}

.thanks-container h3 {
  font-size: 1.7rem;
  margin-bottom: 2rem;
  color: #00a2e9;
}

.thanks-container p {
  color: #000000;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}
