.validation-form h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #00a2e9;
  text-align: center;
}

.validation-form {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza horizontalmente */
}

.validation-form .MuiFormControlLabel-root {
  margin-bottom: 10px;
  text-align: center;
  margin-right: 100px;
  margin-left: 100px;
}

.spacing-margin {
  margin-top: 30px;
  text-align: center;
}

.validation-form .MuiCheckbox-root {
  padding: 0;
}

/* Estilo responsivo para dispositivos móveis */
@media (max-width: 600px) {
  .validation-form .MuiFormControlLabel-root {
    margin-bottom: 10px;
    text-align: center;
  }

  .spacing-margin {
    margin-left: auto;
    margin-right: auto;
  }
}
