/* Estilo para centralizar o modal */
.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Estilo para o conteúdo do modal */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 90%; /* Reduzido o valor para ocupar mais espaço na tela móvel */
  position: relative; /* Para que o botão de fechar fique posicionado corretamente */
}

h3 {
  color: #00a2e9;
}

h4 {
  color: #00a2e9;
}

.p-modal-link {
  text-decoration: none;
  color: #00a2e9;
  font-weight: bold;
}

.close-button-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 20px; /* Reduzido o tamanho do botão de fechar */
  height: 20px; /* Reduzido o tamanho do botão de fechar */
}

.close-button-modal:hover {
  background-color: #f0f0f0;
  border-radius: 50%;
}

/* Estilos para o título */
.title-modal {
  margin-bottom: 20px;
}

/* Estilos para os passos */
.steps-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.step-modal {
  display: flex;
  align-items: center;
  gap: 15px;
}

.step-number-modal {
  width: 30px;
  height: 30px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.step-content-modal {
  flex: 1;
  text-align: left;
}

/* Outros estilos... */

/* Estilos para a versão móvel */
@media (max-width: 480px) {
  .modal-content {
    max-width: 80%;
    max-height: 90vh; /* Usando vh para controlar a altura máxima */
    padding: 10px;
    overflow-y: auto; /* Adicionando scroll vertical quando o conteúdo exceder a altura */
  }

  .close-button-modal {
    width: 16px;
    height: 16px;
  }

  .step-number-modal {
    width: 24px;
    height: 24px;
    font-size: 0.8rem;
  }
}
